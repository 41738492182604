import { useState, useEffect, FormEvent } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BackToTop from "../../components/BackToTop";
import Sidebar from "../../components/Sidebar";
import HeaderRight from "../../components/HeaderRight";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Preloader from "../../components/Preloader";

const InternationalTransfer = () => {
  const [accountName, setAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [country, setCountry] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [stepOneCompleted, setStepOneCompleted] = useState(false);
  const [stepTwoCompleted, setStepTwoCompleted] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [inputOne, setInputOne] = useState("");
  const [inputTwo, setInputTwo] = useState("");
  const [inputThree, setInputThree] = useState("");
  const [inputFour, setInputFour] = useState("");
  const [inputFive, setInputFive] = useState("");
  const [inputSix, setInputSix] = useState("");
  const [code, setCode] = useState("");
  const [transferCompleted, setTransferCompleted] = useState(false);

  const user = useSelector((state: any) => state.user.currentUser);

  useEffect(() => {
    setCode(
      `${inputOne}${inputTwo}${inputThree}${inputFour}${inputFive}${inputSix}`
    );
  }, [inputOne, inputTwo, inputThree, inputFour, inputFive, inputSix]);

  let digitValidate = function (e: any) {
    e.target.value = e.target.value?.replace(/[^0-9]/g, "");
  };

  let tabChange = function (val: number) {
    let ele: any = document.querySelectorAll(".otp");
    if (ele[val - 1].value !== "" && val < 6) {
      ele[val].focus();
    } else if (ele[val - 1].value === "" && val > 1) {
      ele[val - 2].focus();
    }
  };

  const sendOtp = async () => {
    setPageLoading(true);
    const token = localStorage.getItem("firstwebster");

    try {
      await axios.post(
        `https://api.firstwebster.com/api/transaction/initiate/${user?._id}`,
        {
          user,
          amount,
          description,
          email: user?.email,
          transactionId: new Date().getTime().toString(),
          transferOption: "International",
          dateTime: new Date(),
          bankDetails: {
            accountName,
            accountNumber,
            bankName,
            routingNumber,
            country,
            swiftCode,
            accountType,
          },
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setPageLoading(false);
      setOtpModalOpen(true);
    } catch (error: any) {
      setPageLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const checkOtp = async (e: FormEvent) => {
    e.preventDefault();

    setPageLoading(true);
    const token = localStorage.getItem("firstwebster");

    try {
      await axios.post(
        `https://api.firstwebster.com/api/transaction/verify/${user?._id}`,
        { otp: +code },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setPageLoading(false);
      setOtpModalOpen(false);
      setTransferCompleted(true);
    } catch (error: any) {
      setPageLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      {pageLoading && <Preloader />}
      <BackToTop />
      <div className="page-wrapper bg-overlay-base">
        <Sidebar />
        <div className="main-wrapper">
          <div className="main-body-wrapper">
            <nav className="navbar-wrapper">
              <div className="dashboard-title-part">
                <div className="left">
                  <div className="icon">
                    <button className="sidebar-menu-bar">
                      <i className="fas fa-exchange-alt" />
                    </button>
                  </div>
                  <div className="dashboard-path">
                    <span className="main-path" style={{ color: "#0d6efd" }}>
                      <Link to="/dashboard">Dashboard</Link>
                    </span>
                    <i className="las la-angle-right" />
                    <span className="active-path">International Transfer</span>
                  </div>
                </div>
                <HeaderRight />
              </div>
            </nav>

            {user?.transferDisabled ? (
              <div className="body-wrapper">
                <div className="transfer-section">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="transfer-item">
                        <form className="row g-4">
                          <div className="col-12">
                            <div className="row">
                              <img
                                src="assets/images/error.png"
                                alt="error"
                                style={{ width: "60%", margin: "auto" }}
                              />
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "12px",
                                }}
                              >
                                Account on hold. Please contact support
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!stepOneCompleted && !transferCompleted && (
                  <div className="body-wrapper">
                    <div className="row mb-20-none">
                      <div className="col-xl-12 col-lg-12 mb-20">
                        <div className="custom-card mt-10">
                          <div className="dashboard-header-wrapper">
                            <h4 className="title">Transaction Details</h4>
                            <div className="dashboard-btn-wrapper">
                              <div className="dashboard-btn">
                                <button
                                  className="btn--base"
                                  data-bs-toggle="modal"
                                  data-bs-target="#Beneficiary-modal"
                                >
                                  <i className="las la-plus me-1"></i> Add
                                  Beneficiary
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="personal-details pb-4">
                              <form onSubmit={() => setStepOneCompleted(true)}>
                                <div className="row">
                                  <div className="col-lg-3 col-md-6">
                                    <label className="title">
                                      Account Name<span>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="text"
                                      className="form--control"
                                      placeholder="Enter account name"
                                      required
                                      value={accountName}
                                      onChange={(e) =>
                                        setAccountName(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-3 col-md-6">
                                    <label className="title">
                                      Account Number<span>*</span>
                                    </label>
                                    <input
                                      type="number"
                                      pattern="[0-9]*"
                                      name="text"
                                      className="form--control"
                                      placeholder="Enter account number"
                                      required
                                      value={accountNumber}
                                      onChange={(e) =>
                                        setAccountNumber(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-3 col-md-6">
                                    <label className="title">
                                      Bank Name<span>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="text"
                                      className="form--control"
                                      placeholder="Enter bank name"
                                      required
                                      value={bankName}
                                      onChange={(e) =>
                                        setBankName(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-3 col-md-6">
                                    <label className="title">
                                      Routing Number<span>*</span>
                                    </label>
                                    <input
                                      type="number"
                                      pattern="[0-9]*"
                                      name="text"
                                      className="form--control"
                                      placeholder="Enter routing number"
                                      required
                                      value={routingNumber}
                                      onChange={(e) =>
                                        setRoutingNumber(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="title">
                                      Swift Code<span>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="text"
                                      className="form--control"
                                      placeholder="Enter swift code"
                                      required
                                      value={swiftCode}
                                      onChange={(e) =>
                                        setSwiftCode(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="title">
                                      Select Country<span>*</span>
                                    </label>
                                    <select
                                      name="cout"
                                      className="form--control"
                                      required
                                      value={country}
                                      onChange={(e) =>
                                        setCountry(e.target.value)
                                      }
                                    >
                                      <option value="">-</option>
                                      <option value="Afganistan">
                                        Afghanistan
                                      </option>
                                      <option value="Albania">Albania</option>
                                      <option value="Algeria">Algeria</option>
                                      <option value="American Samoa">
                                        American Samoa
                                      </option>
                                      <option value="Andorra">Andorra</option>
                                      <option value="Angola">Angola</option>
                                      <option value="Anguilla">Anguilla</option>
                                      <option value="Antigua &amp; Barbuda">
                                        Antigua &amp; Barbuda
                                      </option>
                                      <option value="Argentina">
                                        Argentina
                                      </option>
                                      <option value="Armenia">Armenia</option>
                                      <option value="Aruba">Aruba</option>
                                      <option value="Australia">
                                        Australia
                                      </option>
                                      <option value="Austria">Austria</option>
                                      <option value="Azerbaijan">
                                        Azerbaijan
                                      </option>
                                      <option value="Bahamas">Bahamas</option>
                                      <option value="Bahrain">Bahrain</option>
                                      <option value="Bangladesh">
                                        Bangladesh
                                      </option>
                                      <option value="Barbados">Barbados</option>
                                      <option value="Belarus">Belarus</option>
                                      <option value="Belgium">Belgium</option>
                                      <option value="Belize">Belize</option>
                                      <option value="Benin">Benin</option>
                                      <option value="Bermuda">Bermuda</option>
                                      <option value="Bhutan">Bhutan</option>
                                      <option value="Bolivia">Bolivia</option>
                                      <option value="Bonaire">Bonaire</option>
                                      <option value="Bosnia &amp; Herzegovina">
                                        Bosnia &amp; Herzegovina
                                      </option>
                                      <option value="Botswana">Botswana</option>
                                      <option value="Brazil">Brazil</option>
                                      <option value="British Indian Ocean Ter">
                                        British Indian Ocean Ter
                                      </option>
                                      <option value="Brunei">Brunei</option>
                                      <option value="Bulgaria">Bulgaria</option>
                                      <option value="Burkina Faso">
                                        Burkina Faso
                                      </option>
                                      <option value="Burundi">Burundi</option>
                                      <option value="Cambodia">Cambodia</option>
                                      <option value="Cameroon">Cameroon</option>
                                      <option value="Canada">Canada</option>
                                      <option value="Canary Islands">
                                        Canary Islands
                                      </option>
                                      <option value="Cape Verde">
                                        Cape Verde
                                      </option>
                                      <option value="Cayman Islands">
                                        Cayman Islands
                                      </option>
                                      <option value="Central African Republic">
                                        Central African Republic
                                      </option>
                                      <option value="Chad">Chad</option>
                                      <option value="Channel Islands">
                                        Channel Islands
                                      </option>
                                      <option value="Chile">Chile</option>
                                      <option value="China">China</option>
                                      <option value="Christmas Island">
                                        Christmas Island
                                      </option>
                                      <option value="Cocos Island">
                                        Cocos Island
                                      </option>
                                      <option value="Colombia">Colombia</option>
                                      <option value="Comoros">Comoros</option>
                                      <option value="Congo">Congo</option>
                                      <option value="Cook Islands">
                                        Cook Islands
                                      </option>
                                      <option value="Costa Rica">
                                        Costa Rica
                                      </option>
                                      <option value="Cote DIvoire">
                                        Cote DIvoire
                                      </option>
                                      <option value="Croatia">Croatia</option>
                                      <option value="Cuba">Cuba</option>
                                      <option value="Curaco">Curacao</option>
                                      <option value="Cyprus">Cyprus</option>
                                      <option value="Czech Republic">
                                        Czech Republic
                                      </option>
                                      <option value="Denmark">Denmark</option>
                                      <option value="Djibouti">Djibouti</option>
                                      <option value="Dominica">Dominica</option>
                                      <option value="Dominican Republic">
                                        Dominican Republic
                                      </option>
                                      <option value="East Timor">
                                        East Timor
                                      </option>
                                      <option value="Ecuador">Ecuador</option>
                                      <option value="Egypt">Egypt</option>
                                      <option value="El Salvador">
                                        El Salvador
                                      </option>
                                      <option value="Equatorial Guinea">
                                        Equatorial Guinea
                                      </option>
                                      <option value="Eritrea">Eritrea</option>
                                      <option value="Estonia">Estonia</option>
                                      <option value="Ethiopia">Ethiopia</option>
                                      <option value="Falkland Islands">
                                        Falkland Islands
                                      </option>
                                      <option value="Faroe Islands">
                                        Faroe Islands
                                      </option>
                                      <option value="Fiji">Fiji</option>
                                      <option value="Finland">Finland</option>
                                      <option value="France">France</option>
                                      <option value="French Guiana">
                                        French Guiana
                                      </option>
                                      <option value="French Polynesia">
                                        French Polynesia
                                      </option>
                                      <option value="French Southern Ter">
                                        French Southern Ter
                                      </option>
                                      <option value="Gabon">Gabon</option>
                                      <option value="Gambia">Gambia</option>
                                      <option value="Georgia">Georgia</option>
                                      <option value="Germany">Germany</option>
                                      <option value="Ghana">Ghana</option>
                                      <option value="Gibraltar">
                                        Gibraltar
                                      </option>
                                      <option value="Great Britain">
                                        Great Britain
                                      </option>
                                      <option value="Greece">Greece</option>
                                      <option value="Greenland">
                                        Greenland
                                      </option>
                                      <option value="Grenada">Grenada</option>
                                      <option value="Guadeloupe">
                                        Guadeloupe
                                      </option>
                                      <option value="Guam">Guam</option>
                                      <option value="Guatemala">
                                        Guatemala
                                      </option>
                                      <option value="Guinea">Guinea</option>
                                      <option value="Guyana">Guyana</option>
                                      <option value="Haiti">Haiti</option>
                                      <option value="Hawaii">Hawaii</option>
                                      <option value="Honduras">Honduras</option>
                                      <option value="Hong Kong">
                                        Hong Kong
                                      </option>
                                      <option value="Hungary">Hungary</option>
                                      <option value="Iceland">Iceland</option>
                                      <option value="Indonesia">
                                        Indonesia
                                      </option>
                                      <option value="India">India</option>
                                      <option value="Iran">Iran</option>
                                      <option value="Iraq">Iraq</option>
                                      <option value="Ireland">Ireland</option>
                                      <option value="Isle of Man">
                                        Isle of Man
                                      </option>
                                      <option value="Israel">Israel</option>
                                      <option value="Italy">Italy</option>
                                      <option value="Jamaica">Jamaica</option>
                                      <option value="Japan">Japan</option>
                                      <option value="Jordan">Jordan</option>
                                      <option value="Kazakhstan">
                                        Kazakhstan
                                      </option>
                                      <option value="Kenya">Kenya</option>
                                      <option value="Kiribati">Kiribati</option>
                                      <option value="Korea North">
                                        Korea North
                                      </option>
                                      <option value="Korea Sout">
                                        Korea South
                                      </option>
                                      <option value="Kuwait">Kuwait</option>
                                      <option value="Kyrgyzstan">
                                        Kyrgyzstan
                                      </option>
                                      <option value="Laos">Laos</option>
                                      <option value="Latvia">Latvia</option>
                                      <option value="Lebanon">Lebanon</option>
                                      <option value="Lesotho">Lesotho</option>
                                      <option value="Liberia">Liberia</option>
                                      <option value="Libya">Libya</option>
                                      <option value="Liechtenstein">
                                        Liechtenstein
                                      </option>
                                      <option value="Lithuania">
                                        Lithuania
                                      </option>
                                      <option value="Luxembourg">
                                        Luxembourg
                                      </option>
                                      <option value="Macau">Macau</option>
                                      <option value="Macedonia">
                                        Macedonia
                                      </option>
                                      <option value="Madagascar">
                                        Madagascar
                                      </option>
                                      <option value="Malaysia">Malaysia</option>
                                      <option value="Malawi">Malawi</option>
                                      <option value="Maldives">Maldives</option>
                                      <option value="Mali">Mali</option>
                                      <option value="Malta">Malta</option>
                                      <option value="Marshall Islands">
                                        Marshall Islands
                                      </option>
                                      <option value="Martinique">
                                        Martinique
                                      </option>
                                      <option value="Mauritania">
                                        Mauritania
                                      </option>
                                      <option value="Mauritius">
                                        Mauritius
                                      </option>
                                      <option value="Mayotte">Mayotte</option>
                                      <option value="Mexico">Mexico</option>
                                      <option value="Midway Islands">
                                        Midway Islands
                                      </option>
                                      <option value="Moldova">Moldova</option>
                                      <option value="Monaco">Monaco</option>
                                      <option value="Mongolia">Mongolia</option>
                                      <option value="Montserrat">
                                        Montserrat
                                      </option>
                                      <option value="Morocco">Morocco</option>
                                      <option value="Mozambique">
                                        Mozambique
                                      </option>
                                      <option value="Myanmar">Myanmar</option>
                                      <option value="Nambia">Nambia</option>
                                      <option value="Nauru">Nauru</option>
                                      <option value="Nepal">Nepal</option>
                                      <option value="Netherland Antilles">
                                        Netherland Antilles
                                      </option>
                                      <option value="Netherlands">
                                        Netherlands (Holland, Europe)
                                      </option>
                                      <option value="Nevis">Nevis</option>
                                      <option value="New Caledonia">
                                        New Caledonia
                                      </option>
                                      <option value="New Zealand">
                                        New Zealand
                                      </option>
                                      <option value="Nicaragua">
                                        Nicaragua
                                      </option>
                                      <option value="Niger">Niger</option>
                                      <option value="Nigeria">Nigeria</option>
                                      <option value="Niue">Niue</option>
                                      <option value="Norfolk Island">
                                        Norfolk Island
                                      </option>
                                      <option value="Norway">Norway</option>
                                      <option value="Oman">Oman</option>
                                      <option value="Pakistan">Pakistan</option>
                                      <option value="Palau Island">
                                        Palau Island
                                      </option>
                                      <option value="Palestine">
                                        Palestine
                                      </option>
                                      <option value="Panama">Panama</option>
                                      <option value="Papua New Guinea">
                                        Papua New Guinea
                                      </option>
                                      <option value="Paraguay">Paraguay</option>
                                      <option value="Peru">Peru</option>
                                      <option value="Phillipines">
                                        Philippines
                                      </option>
                                      <option value="Pitcairn Island">
                                        Pitcairn Island
                                      </option>
                                      <option value="Poland">Poland</option>
                                      <option value="Portugal">Portugal</option>
                                      <option value="Puerto Rico">
                                        Puerto Rico
                                      </option>
                                      <option value="Qatar">Qatar</option>
                                      <option value="Republic of Montenegro">
                                        Republic of Montenegro
                                      </option>
                                      <option value="Republic of Serbia">
                                        Republic of Serbia
                                      </option>
                                      <option value="Reunion">Reunion</option>
                                      <option value="Romania">Romania</option>
                                      <option value="Russia">Russia</option>
                                      <option value="Rwanda">Rwanda</option>
                                      <option value="St Barthelemy">
                                        St Barthelemy
                                      </option>
                                      <option value="St Eustatius">
                                        St Eustatius
                                      </option>
                                      <option value="St Helena">
                                        St Helena
                                      </option>
                                      <option value="St Kitts-Nevis">
                                        St Kitts-Nevis
                                      </option>
                                      <option value="St Lucia">St Lucia</option>
                                      <option value="St Maarten">
                                        St Maarten
                                      </option>
                                      <option value="St Pierre &amp; Miquelon">
                                        St Pierre &amp; Miquelon
                                      </option>
                                      <option value="St Vincent &amp; Grenadines">
                                        St Vincent &amp; Grenadines
                                      </option>
                                      <option value="Saipan">Saipan</option>
                                      <option value="Samoa">Samoa</option>
                                      <option value="Samoa American">
                                        Samoa American
                                      </option>
                                      <option value="San Marino">
                                        San Marino
                                      </option>
                                      <option value="Sao Tome &amp; Principe">
                                        Sao Tome &amp; Principe
                                      </option>
                                      <option value="Saudi Arabia">
                                        Saudi Arabia
                                      </option>
                                      <option value="Senegal">Senegal</option>
                                      <option value="Seychelles">
                                        Seychelles
                                      </option>
                                      <option value="Sierra Leone">
                                        Sierra Leone
                                      </option>
                                      <option value="Singapore">
                                        Singapore
                                      </option>
                                      <option value="Slovakia">Slovakia</option>
                                      <option value="Slovenia">Slovenia</option>
                                      <option value="Solomon Islands">
                                        Solomon Islands
                                      </option>
                                      <option value="Somalia">Somalia</option>
                                      <option value="South Africa">
                                        South Africa
                                      </option>
                                      <option value="Spain">Spain</option>
                                      <option value="Sri Lanka">
                                        Sri Lanka
                                      </option>
                                      <option value="Sudan">Sudan</option>
                                      <option value="Suriname">Suriname</option>
                                      <option value="Swaziland">
                                        Swaziland
                                      </option>
                                      <option value="Sweden">Sweden</option>
                                      <option value="Switzerland">
                                        Switzerland
                                      </option>
                                      <option value="Syria">Syria</option>
                                      <option value="Tahiti">Tahiti</option>
                                      <option value="Taiwan">Taiwan</option>
                                      <option value="Tajikistan">
                                        Tajikistan
                                      </option>
                                      <option value="Tanzania">Tanzania</option>
                                      <option value="Thailand">Thailand</option>
                                      <option value="Togo">Togo</option>
                                      <option value="Tokelau">Tokelau</option>
                                      <option value="Tonga">Tonga</option>
                                      <option value="Trinidad &amp; Tobago">
                                        Trinidad &amp; Tobago
                                      </option>
                                      <option value="Tunisia">Tunisia</option>
                                      <option value="Turkey">Turkey</option>
                                      <option value="Turkmenistan">
                                        Turkmenistan
                                      </option>
                                      <option value="Turks &amp; Caicos Is">
                                        Turks &amp; Caicos Is
                                      </option>
                                      <option value="Tuvalu">Tuvalu</option>
                                      <option value="Uganda">Uganda</option>
                                      <option value="United Kingdom">
                                        United Kingdom
                                      </option>
                                      <option value="Ukraine">Ukraine</option>
                                      <option value="United Arab Erimates">
                                        United Arab Emirates
                                      </option>
                                      <option value="United States of America">
                                        United States of America
                                      </option>
                                      <option value="Uraguay">Uruguay</option>
                                      <option value="Uzbekistan">
                                        Uzbekistan
                                      </option>
                                      <option value="Vanuatu">Vanuatu</option>
                                      <option value="Vatican City State">
                                        Vatican City State
                                      </option>
                                      <option value="Venezuela">
                                        Venezuela
                                      </option>
                                      <option value="Vietnam">Vietnam</option>
                                      <option value="Virgin Islands (Brit)">
                                        Virgin Islands (Brit)
                                      </option>
                                      <option value="Virgin Islands (USA)">
                                        Virgin Islands (USA)
                                      </option>
                                      <option value="Wake Island">
                                        Wake Island
                                      </option>
                                      <option value="Wallis &amp; Futana Is">
                                        Wallis &amp; Futana Is
                                      </option>
                                      <option value="Yemen">Yemen</option>
                                      <option value="Zaire">Zaire</option>
                                      <option value="Zambia">Zambia</option>
                                      <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                  </div>
                                  <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="title">
                                      Account Type<span>*</span>
                                    </label>
                                    <select
                                      className="form--control"
                                      value={accountType}
                                      onChange={(e) =>
                                        setAccountType(e.target.value)
                                      }
                                      required
                                    >
                                      <option value="">--</option>
                                      <option value="Savings account">
                                        Savings account
                                      </option>
                                      <option value="Current account">
                                        Current account
                                      </option>
                                      <option value="Checking account">
                                        Checking account
                                      </option>
                                      <option value="Fixed Deposit">
                                        Fixed Deposit
                                      </option>
                                      <option value="Non-resident account">
                                        Non-resident account
                                      </option>
                                      <option value="Online banking">
                                        Online banking
                                      </option>
                                      <option value="Domiciliary account">
                                        Domiciliary account
                                      </option>
                                      <option value="Joint account">
                                        Joint account
                                      </option>
                                    </select>
                                  </div>
                                  <div
                                    className="col-lg-12 col-md-6"
                                    style={{ marginTop: "24px" }}
                                  >
                                    <label className="title">
                                      Description<span>*</span>
                                    </label>
                                    <textarea
                                      className="form--control"
                                      placeholder="Description"
                                      required
                                      value={description}
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div
                                    className="col-xl-12 col-lg-12"
                                    style={{ marginTop: "32px" }}
                                  >
                                    <button
                                      type="submit"
                                      className="btn--base w-100"
                                    >
                                      Continue
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {stepOneCompleted &&
                  !stepTwoCompleted &&
                  !otpModalOpen &&
                  !transferCompleted && (
                    <div className="body-wrapper pt-60">
                      <div className="payment-information">
                        <form
                          className="row justify-content-center"
                          onSubmit={(e) => {
                            e.preventDefault();
                            if (+amount <= +user.balance) {
                              setStepTwoCompleted(true);
                            } else {
                              toast.error(
                                "Insufficient balance. Please top up your account balance."
                              );
                            }
                          }}
                        >
                          <div className="col-lg-4 col-md-4">
                            <div className="row">
                              <div className="form-group">
                                <p>
                                  Enter Amount ($)
                                  <span className="text--base">*</span>
                                </p>
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  className="form--control"
                                  placeholder="Enter Amount"
                                  required
                                  value={amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                />

                                {amount && (
                                  <ul className="charges">
                                    <li>
                                      Total Fees &amp; Charges{" "}
                                      <b>$1 = ${+amount + 1}</b>
                                    </li>
                                  </ul>
                                )}
                                <div className="button">
                                  <button
                                    className="btn--base w-100 mt-30"
                                    type="submit"
                                  >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                {stepTwoCompleted &&
                  stepOneCompleted &&
                  !otpModalOpen &&
                  !transferCompleted && (
                    <div className="body-wrapper pt-80">
                      <div className="row justify-content-center">
                        <div className="col-lg-5">
                          <div className="review-area">
                            <div className="previw-item">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Account Holder</div>
                                <div className="information-title">
                                  {accountName}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Account Number</div>
                                <div className="information-title">
                                  {accountNumber}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Account Type</div>
                                <div className="information-title">
                                  {accountType}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Bank Name</div>
                                <div className="information-title">
                                  {bankName}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Routing Number</div>
                                <div className="information-title">
                                  {routingNumber}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Swift Code</div>
                                <div className="information-title">
                                  {swiftCode}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Country</div>
                                <div className="information-title">
                                  {country}
                                </div>
                              </div>
                            </div>

                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Description</div>
                                <div className="information-title">
                                  {description}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">
                                  Payabale Amount
                                </div>
                                <div className="information-title">
                                  ${+amount + 1}
                                </div>
                              </div>
                            </div>
                            <div className="payment-button">
                              <button
                                className="btn--base w-100 mt-30"
                                onClick={sendOtp}
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {otpModalOpen && !transferCompleted && (
                  <section className="verification-otp">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className=" col-xl-6 col-lg-8 col-md-10 col-sm-12">
                          <div className="verification-otp-area">
                            <div className="account-wrapper otp-verification">
                              <div className="verification-otp-content ptb-30">
                                <h4 className="title text-center">
                                  Confirm Transfer
                                </h4>
                                <p className="d-block text-center">
                                  We sent an otp to your email address. Please
                                  input the code below to confirm your
                                  transaction.
                                </p>
                              </div>
                              <form
                                className="account-form"
                                onSubmit={checkOtp}
                              >
                                <div className="row ml-b-20">
                                  <div className="col-lg-12 form-group text-center">
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(1)}
                                      maxLength={1}
                                      required
                                      value={inputOne}
                                      onChange={(e) =>
                                        setInputOne(e.target.value)
                                      }
                                    />
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(2)}
                                      maxLength={1}
                                      required
                                      value={inputTwo}
                                      onChange={(e) =>
                                        setInputTwo(e.target.value)
                                      }
                                    />
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(3)}
                                      maxLength={1}
                                      required
                                      value={inputThree}
                                      onChange={(e) =>
                                        setInputThree(e.target.value)
                                      }
                                    />
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(4)}
                                      maxLength={1}
                                      required
                                      value={inputFour}
                                      onChange={(e) =>
                                        setInputFour(e.target.value)
                                      }
                                    />
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(5)}
                                      maxLength={1}
                                      required
                                      value={inputFive}
                                      onChange={(e) =>
                                        setInputFive(e.target.value)
                                      }
                                    />
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(6)}
                                      maxLength={1}
                                      required
                                      value={inputSix}
                                      onChange={(e) =>
                                        setInputSix(e.target.value)
                                      }
                                    />
                                  </div>

                                  {code.length === 6 && (
                                    <div className="col-lg-12 form-group text-center">
                                      <button
                                        className="btn--base btn w-100"
                                        type="submit"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {transferCompleted && (
                  <section className="verification-otp">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className=" col-xl-6 col-lg-8 col-md-10 col-sm-12">
                          <div className="verification-otp-area">
                            <div className="account-wrapper otp-verification">
                              <div
                                className="verification-otp-content ptb-30"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <h4
                                  className="title text-center"
                                  style={{ fontSize: "28px" }}
                                >
                                  Success
                                </h4>
                                <img
                                  src="assets/images/success.gif"
                                  alt="icon"
                                  style={{
                                    width: "400px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                />
                                <p
                                  className="d-block text-center"
                                  style={{ fontSize: "20px" }}
                                >
                                  Your transfer of ${amount} is being reviewed.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="Beneficiary-modal"
        tabIndex={-1}
        aria-labelledby="BeneficiaryModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" id="BeneficiaryModal">
              <h4 className="modal-title">Add Beneficiary</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="las la-times" />
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-4">
                <div className="col-12">
                  <div className="row">
                    <div className="form-group">
                      <label className="title">
                        Account Holder Name<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Name"
                      />
                      <label className="title pt-2">
                        Short Name<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Name"
                      />
                      <label className="title pt-2">
                        Bank Name<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Bank Name"
                      />
                      <label className="title pt-2">
                        Account Number<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Account Number"
                      />
                      <label className="title pt-2">
                        Email Address<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Email Address"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn--base w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        hideProgressBar
        theme="colored"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default InternationalTransfer;
