import { useState, useEffect, FormEvent } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BackToTop from "../../components/BackToTop";
import Sidebar from "../../components/Sidebar";
import HeaderRight from "../../components/HeaderRight";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Preloader from "../../components/Preloader";

const LocalTransfer = () => {
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [bankName, setBankName] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [stepOneCompleted, setStepOneCompleted] = useState(false);
  const [stepTwoCompleted, setStepTwoCompleted] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [inputOne, setInputOne] = useState("");
  const [inputTwo, setInputTwo] = useState("");
  const [inputThree, setInputThree] = useState("");
  const [inputFour, setInputFour] = useState("");
  const [inputFive, setInputFive] = useState("");
  const [inputSix, setInputSix] = useState("");
  const [code, setCode] = useState("");
  const [transferCompleted, setTransferCompleted] = useState(false);

  const user = useSelector((state: any) => state.user.currentUser);

  useEffect(() => {
    setCode(
      `${inputOne}${inputTwo}${inputThree}${inputFour}${inputFive}${inputSix}`
    );
  }, [inputOne, inputTwo, inputThree, inputFour, inputFive, inputSix]);

  let digitValidate = function (e: any) {
    e.target.value = e.target.value?.replace(/[^0-9]/g, "");
  };

  let tabChange = function (val: number) {
    let ele: any = document.querySelectorAll(".otp");
    if (ele[val - 1].value !== "" && val < 6) {
      ele[val].focus();
    } else if (ele[val - 1].value === "" && val > 1) {
      ele[val - 2].focus();
    }
  };

  const sendOtp = async () => {
    setPageLoading(true);
    const token = localStorage.getItem("firstwebster");

    try {
      await axios.post(
        `https://api.firstwebster.com/api/transaction/initiate/${user?._id}`,
        {
          user,
          amount,
          description,
          email: user?.email,
          transactionId: new Date().getTime().toString(),
          transferOption: "Local",
          dateTime: new Date(),
          bankDetails: {
            accountName,
            bankName,
            accountNumber,
            accountType,
          },
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setPageLoading(false);
      setOtpModalOpen(true);
    } catch (error: any) {
      setPageLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const checkOtp = async (e: FormEvent) => {
    e.preventDefault();

    setPageLoading(true);
    const token = localStorage.getItem("firstwebster");

    try {
      await axios.post(
        `https://api.firstwebster.com/api/transaction/verify/${user?._id}`,
        { otp: +code },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setPageLoading(false);
      setOtpModalOpen(false);
      setTransferCompleted(true);
    } catch (error: any) {
      setPageLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      {pageLoading && <Preloader />}
      <BackToTop />
      <div className="page-wrapper bg-overlay-base">
        <Sidebar />
        <div className="main-wrapper">
          <div className="main-body-wrapper">
            <nav className="navbar-wrapper">
              <div className="dashboard-title-part">
                <div className="left">
                  <div className="icon">
                    <button className="sidebar-menu-bar">
                      <i className="fas fa-exchange-alt" />
                    </button>
                  </div>
                  <div className="dashboard-path">
                    <span className="main-path" style={{ color: "#0d6efd" }}>
                      <Link to="/dashboard">Dashboard</Link>
                    </span>
                    <i className="las la-angle-right" />
                    <span className="active-path">Local Transfer</span>
                  </div>
                </div>
                <HeaderRight />
              </div>
            </nav>
            {user?.transferDisabled ? (
              <div className="body-wrapper">
                <div className="transfer-section">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="transfer-item">
                        <form className="row g-4">
                          <div className="col-12">
                            <div className="row">
                              <img
                                src="assets/images/error.png"
                                alt="error"
                                style={{ width: "60%", margin: "auto" }}
                              />
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "12px",
                                }}
                              >
                                Account on hold. Please contact support
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!stepOneCompleted && !transferCompleted && (
                  <div className="body-wrapper">
                    <div className="row mb-20-none">
                      <div className="col-xl-12 col-lg-12 mb-20">
                        <div className="custom-card mt-10">
                          <div className="dashboard-header-wrapper">
                            <h4 className="title">Transaction Details</h4>
                            <div className="dashboard-btn-wrapper">
                              <div className="dashboard-btn">
                                <button
                                  className="btn--base"
                                  data-bs-toggle="modal"
                                  data-bs-target="#Beneficiary-modal"
                                >
                                  <i className="las la-plus me-1"></i> Add
                                  Beneficiary
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="personal-details pb-4">
                              <form onSubmit={() => setStepOneCompleted(true)}>
                                <div className="row">
                                  <div className="col-lg-3 col-md-6">
                                    <label className="title">
                                      Account Name<span>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="text"
                                      className="form--control"
                                      placeholder="Enter account name"
                                      required
                                      value={accountName}
                                      onChange={(e) =>
                                        setAccountName(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-3 col-md-6">
                                    <label className="title">
                                      Account Number<span>*</span>
                                    </label>
                                    <input
                                      type="number"
                                      pattern="[0-9]*"
                                      name="text"
                                      className="form--control"
                                      placeholder="Enter account number"
                                      required
                                      value={accountNumber}
                                      onChange={(e) =>
                                        setAccountNumber(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-3 col-md-6">
                                    <label className="title">
                                      Bank Name<span>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="text"
                                      className="form--control"
                                      placeholder="Enter bank name"
                                      required
                                      value={bankName}
                                      onChange={(e) =>
                                        setBankName(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-3 col-md-6">
                                    <label className="title">
                                      Account Type<span>*</span>
                                    </label>
                                    <select
                                      className="form--control"
                                      value={accountType}
                                      onChange={(e) =>
                                        setAccountType(e.target.value)
                                      }
                                      required
                                    >
                                      <option value="">--</option>
                                      <option value="Savings account">
                                        Savings account
                                      </option>
                                      <option value="Current account">
                                        Current account
                                      </option>
                                      <option value="Checking account">
                                        Checking account
                                      </option>
                                      <option value="Fixed Deposit">
                                        Fixed Deposit
                                      </option>
                                      <option value="Non-resident account">
                                        Non-resident account
                                      </option>
                                      <option value="Online banking">
                                        Online banking
                                      </option>
                                      <option value="Domiciliary account">
                                        Domiciliary account
                                      </option>
                                      <option value="Joint account">
                                        Joint account
                                      </option>
                                    </select>
                                  </div>

                                  <div
                                    className="col-lg-12 col-md-6"
                                    style={{ marginTop: "24px" }}
                                  >
                                    <label className="title">
                                      Description<span>*</span>
                                    </label>
                                    <textarea
                                      className="form--control"
                                      placeholder="Description"
                                      required
                                      value={description}
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div
                                    className="col-xl-12 col-lg-12"
                                    style={{ marginTop: "32px" }}
                                  >
                                    <button
                                      type="submit"
                                      className="btn--base w-100"
                                    >
                                      Continue
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {stepOneCompleted &&
                  !stepTwoCompleted &&
                  !otpModalOpen &&
                  !transferCompleted && (
                    <div className="body-wrapper pt-60">
                      <div className="payment-information">
                        <form
                          className="row justify-content-center"
                          onSubmit={(e) => {
                            e.preventDefault();
                            if (+amount <= +user.balance) {
                              setStepTwoCompleted(true);
                            } else {
                              toast.error(
                                "Insufficient balance. Please top up your account balance."
                              );
                            }
                          }}
                        >
                          <div className="col-lg-4 col-md-4">
                            <div className="row">
                              <div className="form-group">
                                <p>
                                  Enter Amount ($)
                                  <span className="text--base">*</span>
                                </p>
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  className="form--control"
                                  placeholder="Enter Amount"
                                  required
                                  value={amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                />
                                <div className="button">
                                  <button
                                    className="btn--base w-100 mt-30"
                                    type="submit"
                                  >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                {stepTwoCompleted &&
                  stepOneCompleted &&
                  !otpModalOpen &&
                  !transferCompleted && (
                    <div className="body-wrapper pt-80">
                      <div className="row justify-content-center">
                        <div className="col-lg-5">
                          <div className="review-area">
                            <div className="previw-item">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Account Holder</div>
                                <div className="information-title">
                                  {accountName}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Account Number</div>
                                <div className="information-title">
                                  {accountNumber}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Account Type</div>
                                <div className="information-title">
                                  {accountType}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Bank Name</div>
                                <div className="information-title">
                                  {bankName}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Description</div>
                                <div className="information-title">
                                  {description}
                                </div>
                              </div>
                            </div>
                            <div className="previw-item mt-3">
                              <div className="review-point d-flex justify-content-between">
                                <div className="name-title">Amount</div>
                                <div className="information-title">
                                  ${amount}
                                </div>
                              </div>
                            </div>
                            <div className="payment-button">
                              <button
                                className="btn--base w-100 mt-30"
                                onClick={sendOtp}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {otpModalOpen && !transferCompleted && (
                  <section className="verification-otp ptb-150">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className=" col-xl-6 col-lg-8 col-md-10 col-sm-12">
                          <div className="verification-otp-area">
                            <div className="account-wrapper otp-verification">
                              <div className="verification-otp-content ptb-30">
                                <h4 className="title text-center">
                                  Confirm Transfer
                                </h4>
                                <p className="d-block text-center">
                                  We sent an otp to your email address. Please
                                  input the code below to confirm your
                                  transaction.
                                </p>
                              </div>
                              <form
                                className="account-form"
                                onSubmit={checkOtp}
                              >
                                <div className="row ml-b-20">
                                  <div className="col-lg-12 form-group text-center">
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(1)}
                                      maxLength={1}
                                      required
                                      value={inputOne}
                                      onChange={(e) =>
                                        setInputOne(e.target.value)
                                      }
                                    />
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(2)}
                                      maxLength={1}
                                      required
                                      value={inputTwo}
                                      onChange={(e) =>
                                        setInputTwo(e.target.value)
                                      }
                                    />
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(3)}
                                      maxLength={1}
                                      required
                                      value={inputThree}
                                      onChange={(e) =>
                                        setInputThree(e.target.value)
                                      }
                                    />
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(4)}
                                      maxLength={1}
                                      required
                                      value={inputFour}
                                      onChange={(e) =>
                                        setInputFour(e.target.value)
                                      }
                                    />
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(5)}
                                      maxLength={1}
                                      required
                                      value={inputFive}
                                      onChange={(e) =>
                                        setInputFive(e.target.value)
                                      }
                                    />
                                    <input
                                      className="otp"
                                      type="text"
                                      onInput={(e: any) => digitValidate(e)}
                                      onKeyUp={() => tabChange(6)}
                                      maxLength={1}
                                      required
                                      value={inputSix}
                                      onChange={(e) =>
                                        setInputSix(e.target.value)
                                      }
                                    />
                                  </div>

                                  {code.length === 6 && (
                                    <div className="col-lg-12 form-group text-center">
                                      <button
                                        className="btn--base btn w-100"
                                        type="submit"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {transferCompleted && (
                  <section className="verification-otp">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className=" col-xl-6 col-lg-8 col-md-10 col-sm-12">
                          <div className="verification-otp-area">
                            <div className="account-wrapper otp-verification">
                              <div
                                className="verification-otp-content ptb-30"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <h4
                                  className="title text-center"
                                  style={{ fontSize: "28px" }}
                                >
                                  Success
                                </h4>
                                <img
                                  src="assets/images/success.gif"
                                  alt="icon"
                                  style={{
                                    width: "400px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                />
                                <p
                                  className="d-block text-center"
                                  style={{ fontSize: "20px" }}
                                >
                                  Your transfer of ${amount} is being reviewed.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="Beneficiary-modal"
        tabIndex={-1}
        aria-labelledby="BeneficiaryModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" id="BeneficiaryModal">
              <h4 className="modal-title">Add Beneficiary</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="las la-times" />
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-4">
                <div className="col-12">
                  <div className="row">
                    <div className="form-group">
                      <label className="title">
                        Account Holder Name<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Name"
                      />
                      <label className="title pt-2">
                        Short Name<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Name"
                      />
                      <label className="title pt-2">
                        Bank Name<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Bank Name"
                      />
                      <label className="title pt-2">
                        Account Number<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Account Number"
                      />
                      <label className="title pt-2">
                        Email Address<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Email Address"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn--base w-100">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        hideProgressBar
        theme="colored"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default LocalTransfer;
